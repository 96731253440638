import '../styles/DateQuestion.scss';
import 'react-datepicker/dist/react-datepicker.css';
import * as React from 'react';
import DatePicker from 'react-datepicker';
import bind from 'bind-decorator';
import moment from 'moment';
import { ClientPersistInterface } from 'Interfaces/ClientPersistInterface';
import { QuestionInterface } from 'Interfaces/Forms/QuestionInterface';
import { Button, Col, Form, Row } from 'react-bootstrap';
import FormUtils from '../utils/FormUtils';
import { LooseObject } from '../../../Interfaces/LooseObject';
import { DataPoint } from '../../../Interfaces/DataPoint';
import { pad } from '../../../utils/utils';
import { checkValidationRuleError } from '../utils/utils';
import QuestionLabel from './QuestionLabel';


interface Props {
  question: QuestionInterface;
  value: null | string;
  edit: boolean;
  updateAnswer: (value: LooseObject) => void;
  formUtils: FormUtils;
  isSubQuestion?: boolean;
  dataPoint: DataPoint;
  clientPersist: ClientPersistInterface;
}

interface State {
  value: string;
  edit: boolean;
  originalValue: null | string;
  maxDate: string;
  minDate: string;
  isSafari: boolean;
  hasError: boolean;
  errorText?: string;
}

export default class DateQuestion extends React.Component <Props, State> {

  constructor(props) {
    super(props);
    const { value, question, clientPersist } = this.props;
    const stateValue = value ? value : question.default ?
      this.getValue(Number(question.default)) : '';

    // US-3524
    const enumDisableClosureDate = question.id.toLowerCase() === 'closuredate' &&
      clientPersist.roles.includes('enumerator');
    const edit = enumDisableClosureDate ? false : props.edit;
    this.state = {
      value: stateValue,
      edit,
      originalValue: value,
      hasError: false,
      maxDate: question.enableMaxDate && question.maxDate !== null ? this.getValue(Number(question.maxDate)) : '',
      minDate: question.enableMinDate && question.minDate !== null ? this.getMinDate(stateValue) : '',
      isSafari: navigator.userAgent.indexOf('Mac') !== -1 &&
         /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
    };
  }

  @bind
  private validateDateManually(value: string) {
    const date = new Date(value);
    if (this.state.maxDate !== '') {
      const maxDate = new Date(this.state.maxDate);
      if (date.getTime() > maxDate.getTime()) {
        return false;
      }
    }
    if (this.state.minDate !== '') {
      const minDate = new Date(this.state.minDate);
      if (date.getTime() < minDate.getTime()) {
        return false;
      }
    }
    return true;
  }

  @bind
  private handleChange(e) {
    const { question, updateAnswer, dataPoint } = this.props;
    const value = e.target.value;
    if (!this.validateDateManually(value)) {
      return;
    }
    this.setState({ value }, () => {
      if (updateAnswer) {
        const newAns = {};
        newAns[question.id] = value;
        if (value === '' && question.id === 'scheduleDate') {
          newAns['scheduleStatus'] = 'Unscheduled';
        } else if (dataPoint['scheduleStatus'] === 'Unscheduled') {
          newAns['scheduleStatus'] = 'Scheduled';
        }
        updateAnswer(newAns);
      }
    });
  }

  @bind
  private handleDatePicked(date) {
    const value = `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(date.getDate())}`;
    const { question, updateAnswer } = this.props;
    this.setState({ value }, () => {
      if (updateAnswer) {
        const newAns = {};
        newAns[question.id] = value;
        updateAnswer(newAns);
      }
    });
  }

  @bind
  private getValue(days: number) {
    const today = new Date();
    today.setDate(today.getDate() + days);
    return `${today.getFullYear()}-${pad(today.getMonth() + 1)}-${pad(today.getDate())}`;
  }

  @bind
  private clearDate() {
    const { question, updateAnswer } = this.props;
    this.setState({ value: '' }, () => {
      if (updateAnswer) {
        const newAns = {};
        newAns[question.id] = '';
        updateAnswer(newAns);
      }
    });
  }

  /*
    Returns the minimum date value.
    If the field had a value already from the db, the value is used as the minimum if it is before the minimum date.
    This is to avoid validation errors.
  */
  @bind
  private getMinDate(value: string) {
    const { question } = this.props;
    if (value && value !== '') {
      const minDate = new Date();
      minDate.setDate(minDate.getDate() + question.minDate);
      const saved = new Date(value);
      const m1 = moment(saved);
      const m2 = moment(minDate);
      const difference = m1.diff(m2, 'days');
      if (difference < 0) {
        return `${saved.getFullYear()}-${pad(saved.getMonth() + 1)}-${pad(saved.getDate())}`;
      }
    }
    return this.getValue(Number(question.minDate));
  }

  public static getDerivedStateFromProps(props: Props, state: State) {
    const { value } = state;
    const dpValue = props.value;
    const { dataPoint, question, formUtils } = props;
    if (value !== dpValue) {
      return { value: dpValue };
    } else if (dataPoint['validate']) {
      if (!question.optional && value === '') {
        return { hasError: true };
      }
      let hasError = false;
      let errorText: string | undefined;
      if (dataPoint['invalidValidationRules']) {
        const ivr = dataPoint['invalidValidationRules'];
        const errors = checkValidationRuleError(question.id, ivr, formUtils);
        hasError = errors.hasError;
        errorText = errors.errorText;
      }
      return { hasError, errorText };
    }
    return null;
  }

  public shouldComponentUpdate(nextProps: Props, nextState: State) {
    return this.state.value !== nextState.value || this.state.edit !== nextState.edit
      || nextProps.dataPoint.validate !== this.props.dataPoint.validate
      || this.state.hasError !== nextState.hasError;
  }

  public render(): JSX.Element {
    const { minDate, maxDate, value, edit, isSafari, hasError, errorText } = this.state;
    const { question, formUtils, dataPoint, isSubQuestion } = this.props;
    const required = question.optional ? null : (<span className="text-danger">{` * `}</span>);
    const hasErrorClass = (required && dataPoint.validate && this.state.value === '' ) || hasError ? 'is-invalid' : '';
    const horizontal = question.showValueInParallel &&
      !formUtils.getModel().responsiveLayout &&
      !`${question.text}`.endsWith('---') ? true : false;
    const input = isSafari ? (
      <DatePicker
        className={`form-control date-question ${hasErrorClass}`}
        wrapperClassName="date-picker"
        onSelect={this.handleDatePicked}
        selected={value ? new Date(value) : undefined}
        minDate={minDate ? new Date(minDate) : undefined}
        maxDate={maxDate ? new Date(maxDate) : undefined}
        disabled={!edit}
      />
    ) : (
      <input
        type="date"
        name={question.id}
        className={`form-control date-question ${hasErrorClass}`}
        value={value}
        disabled={!edit}
        min={minDate}
        max={maxDate}
        onChange={this.handleChange}
      />
    );
    const control = (
      <>
        {input}
        {this.props.edit && (
          <Button
            variant="link"
            onClick={() => this.clearDate()}
          >
            <i className="fa fa-close" />
          </Button>
        )}
      </>
    );
    return (
      <Form.Group
        as={horizontal ? Row : undefined}
        className={`${hasErrorClass} ${
          !horizontal ? formUtils.getResponsiveView(question, isSubQuestion) : 'container-fluid'
        }`}
      >
        <QuestionLabel
          question={question}
          dataPoint={dataPoint}
          formUtils={formUtils}
          horizontal={horizontal}
        >
          {required}
        </QuestionLabel>
        {horizontal ? (
          <Col sm={10}>
            {control}
          </Col>
        ) : control}
        {hasError && errorText && (
          <div className="invalid-feedback">{errorText}</div>
        )}
      </Form.Group>
    );
  }
}
