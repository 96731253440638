import './CollapseMenu.scss';
import * as React from 'react';
import { Nav, Navbar, NavDropdown } from 'react-bootstrap';
import bind from 'bind-decorator';
import ReactGA from 'react-ga';
import * as H from 'history';
import { getLocalization, globalWindow } from '../../global/global';
import { languagesEnum } from '../../Interfaces/ClientPersistInterface';
import { ModalComponentNames } from '../../Interfaces/ModalInterface';
import { CURRENT_SELECTION, HeaderPropsInterface } from './HeaderContainer';

interface IExternalProps {
  clientPersist: HeaderPropsInterface['clientPersist'];
  navigateAddModal: HeaderPropsInterface['actions']['navigateAddModal'];
  navigateRemoveModal: HeaderPropsInterface['actions']['navigateRemoveModal'];
  routerLocation: HeaderPropsInterface['routerLocation'];
  history: H.History;
}

interface Props {
  currentSelection: CURRENT_SELECTION;
}

interface IOwnState {
  alert: null | object;
  showAlert: boolean;
}

const className = 'CollapseMenu';
export default class CollapseMenuClass extends React.Component <IExternalProps & Props, IOwnState> {
  constructor(props) {
    super(props);

    this.state = {
      showAlert: false,
      alert: null
    };
  }

  /*
   *  Opens the link passed.
   */
  @bind
  private openLink(link, name: string = '_parent', module?: string) {
    if (link.endsWith('=')) {
      link = link + this.props.clientPersist.lang;
    }
    return () => {
      if (module) {
        ReactGA.event({
          category: 'module-selection',
          action: `${module}`,
          label: `${this.props.clientPersist.instance}`
        });
      }
      globalWindow.open(link, name);
    };
  }

  /*
   * Shows the dataviewer.
   */
  @bind
  private showDataViewer() {
    if (!['schedule', 'dataviewer'].includes(this.props.currentSelection)) {
      this.openLink(`/index.jsp?lang=${this.props.clientPersist.lang}`)();
    } else if (this.props.currentSelection !== 'dataviewer') {
      this.props.history.push('/');
      // this.setState({currentSelection: 'dataviewer'});
    }
  }

  // Shows the video modal and sets the id passed as id of the video to be displayed.
  @bind
  private showVideo(id: string, module?: string) {
    return () => {
      if (module) {
        ReactGA.event({
          category: 'module-selection',
          action: `${module}`,
          label: `${this.props.clientPersist.instance}`
        });
      }
      this.props.navigateAddModal({
        component: ModalComponentNames.ShowVideoModal,
        props: {
          onClose: () => this.props.navigateRemoveModal(ModalComponentNames.ShowVideoModal),
          videoId: id
        }
      });
    };
  }

  private getHelpDropdown(): JSX.Element | null {
    const { clientPersist } = this.props;
    const {roles, epaymentUser, paymentMode } = clientPersist;
    const manualLang = `${clientPersist.lang}` === 'fr' ? 'fr' : '';
    if (roles.indexOf('enumerator') !== -1) {
      return null;
    }
    const menuItem = (
      eventKey: number, link: string, locale: string, isVideo: boolean = false,
      module?: string
    ): JSX.Element => {
      const openLink = [7.4, 7.5, 7.6].includes(eventKey) ?
        this.openLink(link, '_blank', module) : this.openLink(link, '_parent', module);
      return (
        <NavDropdown.Item
          as={'li'}
          eventKey="1"
          onClick={isVideo ? this.showVideo(link, module) : openLink}
        >
          {getLocalization(locale)}
        </NavDropdown.Item>
      );
    };
    const gettingStartedVideo = menuItem(
      7.2, 'RvnY4nKqtLQ', 'gettingstartedvideo', true, 'GET_STARTED'
    );
    return (
      <NavDropdown
        title={getLocalization('help')}
        id="basic-nav-dropdown"
        active={this.props.currentSelection === 'help'}
      >
        {this.props.clientPersist.instance === 'poimapper' &&
            menuItem(7.1, '/introduction.jsp', 'introduction', false, 'INTRODUCTION'
            )}
        {this.props.clientPersist.lang !== languagesEnum.fi && gettingStartedVideo}
        {roles.includes('admin') &&
           menuItem(7.3, 'ZQeX2R8HRok', 'adminvideo', true, 'ADMIN_VIDEO')}
        {menuItem(
          7.4, `${window.location.origin}/portalmanual/${manualLang}`, 'dataviewermanual', false, 'DV_MANUAL'
        )}
        {roles.includes('modeler') &&
           menuItem(7.5, `${window.location.origin}/formbuildermanual/`, 'formbuildermanual', false, 'FB_MANUAL')}
        {menuItem(7.6, `${window.location.origin}/documents-manual`, 'documentsManual', false, 'DOCUMENTS_MANUAL')}
        {roles.includes('modeler') &&
          menuItem(
            7.7, `${window.location.origin}/basic-fb-manual`, 'basicFbManual', false, 'BASIC_FORM_BUILDER_MANUAL'
          )}
        {roles.includes('modeler') &&
          menuItem(7.8, `${window.location.origin}/av-fb-manual`, 'avFbManual', false, 'AV_FORM_BUILDER_MANUAL')}
        {Boolean(epaymentUser) && paymentMode === 'creditcard' &&
          menuItem(7.9, `${window.location.origin}/payment-manual`, 'paymentManual', false, 'PAYMENT_MANUAL')}
        {menuItem(7.10, `${window.location.origin}/release-notes.jsp`, 'releaseNotes', false, 'RELEASE_NOTES')}
      </NavDropdown>
    );
  }

  getDocuments = () => {
    return (
      <Nav.Item as={'li'} className={`${this.props.currentSelection === 'documents' ? 'active' : ''}`}>
        <Nav.Link
          eventKey="1"
          onClick={this.openLink('/document.jsp?lang=', '_parent', 'DOCUMENTS')}
        >
          {getLocalization('documents')}
        </Nav.Link>
      </Nav.Item>
    );
  };

  public render(): JSX.Element | null {
    const { clientPersist, currentSelection } = this.props;
    const { roles, instance } = clientPersist;
    const dataViewer = Boolean(roles) && (roles.includes('admin') || roles.includes('modeler')) ? (
      <Nav.Item as={'li'} className={`${this.props.currentSelection === 'dataviewer' ? 'active' : ''}`}>
        <Nav.Link
          eventKey="1"
          onClick={this.showDataViewer}
        >
          {getLocalization('dataviewer')}
        </Nav.Link>
      </Nav.Item>
    ) : '';
    const formBuilder = Boolean(roles) && clientPersist.fbEnabled &&
      (roles.includes('admin') || roles.includes('modeler')) ?
      (
        <Nav.Item as={'li'}>
          <Nav.Link
            eventKey="2"
            onClick={this.openLink('/atool.jsp?lang=', '_parent', 'FORMBUILDER')}
          >
            {getLocalization('formbuilder')}
          </Nav.Link>
        </Nav.Item>
      )
      : '';
    const locationHierarchy = Boolean(roles) && clientPersist.fbEnabled &&
      (roles.includes('admin') || roles.includes('modeler')) && (
      <Nav.Item as={'li'} className={`${this.props.currentSelection === 'locationHierarchy' ? 'active' : ''}`}>
        <Nav.Link
          eventKey="3"
          onClick={() => {
            if (instance === 'poimapper') {
              this.props.navigateAddModal({
                component: ModalComponentNames.ConfirmationModal,
                props: {
                  onConfirm: () => this.props.navigateRemoveModal(ModalComponentNames.ConfirmationModal),
                  onClose: () => this.props.navigateRemoveModal(ModalComponentNames.ConfirmationModal),
                  localizations: {
                    cancel: '',
                    confirm: getLocalization('ok'),
                    confirmStyle: 'info',
                    header: (<h4>{getLocalization('info')}</h4>),
                    body: (
                      <p>
                        {getLocalization('upgradeAlert')}
                      </p>
                    )
                  }
                }
              });
              return;
            }
            ReactGA.event({
              category: 'module-selection',
              action: `LOCATION_HIERARCHY`,
              label: `${this.props.clientPersist.instance}`
            });
            globalWindow.open(
              `/locationHierarchy.jsp?lang=${clientPersist.lang}`,
              '_parent'
            );
          }}
        >
          {getLocalization('locationhierarchy')}
        </Nav.Link>
      </Nav.Item>
    );
    const admin = Boolean(roles) !== null && roles.includes('admin') && (
      <Nav.Item as={'li'} className={`${this.props.currentSelection === 'admin' ? 'active' : ''}`}>
        <Nav.Link
          eventKey="4"
          onClick={currentSelection !== 'admin' ?  this.openLink('/admin.jsp?lang=', '_parent', 'ADMIN') : undefined}
        >
          {getLocalization('admin')}
        </Nav.Link>
      </Nav.Item>
    );
    if (globalWindow.mobile && roles.indexOf('enumerator') !== -1) {
      return (<React.Fragment>{this.props.children}</React.Fragment>);
    }
    return (
      <Navbar.Collapse
        id="header-navbar-nav"
        className={className}
      >
        <Nav className="mr-auto">
          {dataViewer}
          {formBuilder}
          {locationHierarchy}
          {admin}
          {this.getHelpDropdown()}
          {this.getDocuments()}
        </Nav>
        {this.props.children}
      </Navbar.Collapse>
    );
  }
}
