export enum languagesEnum {
  fi = 'fi',
  en = 'en',
  es = 'es',
  fr = 'fr',
  de = 'de',
  se = 'se',
  it = 'it',
}

export type TwoFactorMethods = 'EMAIL' | 'AUTHENTICATOR' | 'true';
export interface ClientPersistInterface {
  chartVid: string;
  clusterMap: boolean;
  disableMobileFeatures: boolean;
  epaymentUser: boolean;
  forms: string[];
  fromDate: string;
  instance: string | undefined;
  lang: languagesEnum;
  lastLogin: string;
  locations: (number | string)[];
  new_data_notification: boolean;
  organization: string | undefined;
  package: string;
  paymentMode: string;
  rememberFilterSelections: boolean;
  roles: string;
  showDataAutomatically: boolean;
  toDate: string;
  updated_created_data_notification: boolean;
  updated_data_notification: boolean;
  userName: string | undefined;
  user_id: string | undefined;
  useremail: string;
  users: string[];
  userLevel: string;
  userLocations: string;
  locationLabels: string[];
  payingCustomer: string;
  groupId: number | undefined;
  readOwn: boolean | undefined;
  editOwn: boolean | undefined;
  googleAccessToken: boolean | undefined;
  key: string;
  fullZoom: string | undefined;
  shareDataUnregisteredUsers: boolean;
  accessSharedDataOnly: boolean;
  canDelete: boolean | undefined;
  refreshToken: string | undefined;
  assignedLocationOnly: boolean | undefined;
  oneDriveAccessToken: boolean | undefined;
  sessionTime: number;
  tableViewEditEnabled?: boolean | undefined;
  autoSaveEnabled?: boolean | undefined;
  shareDataUnregisteredUsersViewer: boolean;
  locationHierarchyId: number;
  transactionsBalance?: number | undefined;
  twoFactorAuthEnabled?: TwoFactorMethods;
  checkTwoFactorAuthCode?: boolean;
  require2FA?: 'true' | 'false';
  logout?: boolean;
  passwordExpired?: boolean | undefined;
  licenceAlertShown: boolean | undefined;
  transcriptionLang?: string | null;
  formLanguage?: string;
  fbEnabled: boolean;
}
