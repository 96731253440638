import { translateDataPoint } from 'api/pois';
import { getLocalization } from 'global/global';
import { useAppSelector } from 'index';
import { DataPoint, TranslateDataPointRequest } from 'Interfaces/DataPoint';
import { FormInterface } from 'Interfaces/Forms/FormsInterface';
import { questionTypes } from 'Interfaces/Forms/QuestionInterface';
import * as React from 'react';
import { Button, Container, Form } from 'react-bootstrap';
import { toast } from 'react-toastify';
import translateLanguages from 'utils/translateLanguages';
import { getFormUtils } from 'views/SingleInstance/utils/FormUtilsHolder';

interface Props {
  form: FormInterface;
  dataPoint: DataPoint;
  readOnly: boolean;
  updateAnswer: (dp: DataPoint) => void;
}

export default function DataPointTranslation(props: Props) {
  const [lang] = React.useState(translateLanguages.find(l => l.value === props.form.targetLanguage)?.name);
  const forms = useAppSelector(state => state.forms);

  const getForm = React.useCallback((id) => {
    return forms.collection.find(f => f.ref === id);
  }, [forms]);

  const getDataPoint = (dataPoint: DataPoint) => {
    const form = getForm(dataPoint.questionnaire_id);
    if (form) {
      const formUtils = getFormUtils(form);
      const questions = formUtils.getQuestions();
      const ids = Object.keys(questions);

      const dp: DataPoint = {};
      for (const id of ids) {
        const q = questions[id];
        if (dataPoint[id] && !q.deleted && !q.inVisible) {
          if (q.type === questionTypes.STRING_QUESTION || q.type === questionTypes.NAME_QUESTION) {
            dp[id] = dataPoint[id];
          } else if (
            q.type === questionTypes.TABLE_QUESTION ||
            q.type === questionTypes.REFERENCE_TABLE_QUESTION
          ) {
            const subForms = dataPoint[id];
            const reqSubForms = subForms.map( sf => getDataPoint(sf)).filter(sf => sf);
            if (reqSubForms.length > 0) {
              dp[id] = reqSubForms;
            }
          } else if (
            q.type === questionTypes.PART_OF_QUESTION ||
            q.type === questionTypes.TASK_QUESTION
          ) {
            const subFormId = q.listItems?.relation[0]?.ref;
            if (subFormId) {
              const subForm = getForm(subFormId);
              if (subForm && subForm.targetLanguage) { // Only translate subforms which do not have target language.
                continue;
              }
            }
            const subForms = dataPoint[id];
            const reqSubForms = subForms.map( sf => getDataPoint(sf)).filter(sf => sf);
            if (reqSubForms.length > 0) {
              dp[id] = reqSubForms;
            }
          }
        }
      }
      if (Object.keys(dp).length > 0) {
        dp['id'] = dataPoint['id'];
        return dp;
      }
    }
    return null;
  };

  const mergeArrayData = (translatedData: DataPoint[], data: DataPoint[]) => {
    const newData: DataPoint[] = [];
    const tempData = [...data];
    for (const tranlated of translatedData) {
      const index = tempData.findIndex(d => d.id && d.id === tranlated.id);
      if (index > -1) {
        const dp = tempData[index];
        const newDp = {...dp};
        const keys = Object.keys(tranlated);
        for (const key of keys) {
          if (Array.isArray(tranlated[key])) {
            newDp[key] = mergeArrayData(tranlated[key], dp[key]);
          } else {
            newDp[key] = tranlated[key];
          }
        }
        newData.push(newDp);
        tempData.splice(index, 1);
      }
    }
    return newData;
  };

  const translate = async () => {
    const { form, dataPoint } = props;

    const reqObject: TranslateDataPointRequest = {
      lang: form.targetLanguage || '',
      dataPoint: {}
    };

    const dp = getDataPoint(dataPoint);
    if (dp) {
      reqObject.dataPoint = dp;
      const translateToastId = Date.now() + Math.floor(Math.random() * 100);
      toast(getLocalization('translatingDataPoint'), {
        toastId: translateToastId,
        type: toast.TYPE.INFO,
      });
      const translate = await translateDataPoint(reqObject);
      const json = await translate.json();
      if (json) {
        if (json.errorCode) {
          toast.update(translateToastId, {
            type: toast.TYPE.ERROR,
            render: json.errorMessage,
          });
        } else {
          const keys = Object.keys(json);
          const newDp: DataPoint = {};
          for (const key of keys) {
            if (Array.isArray(json[key])) {
              newDp[key] = mergeArrayData(json[key], dataPoint[key]);
            } else {
              newDp[key] = json[key];
            }
          }
          props.updateAnswer(newDp);
          toast.update(translateToastId, {
            type: toast.TYPE.SUCCESS,
            render: getLocalization('translatedSuccessfully'),
          });
        }
      } else {
        toast.update(translateToastId, {
          type: toast.TYPE.ERROR,
          render: getLocalization('errorTranslatingDatapoint'),
        });
      }
      setTimeout(() => toast.dismiss(translateToastId), 3000);
    }
  };

  return (
    <Container fluid={true} className="mt-2">
      <Form.Label className="mr-3">{`${getLocalization('translateTo')} ${lang}`}</Form.Label>
      <Button
        disabled={props.readOnly}
        onClick={() => void translate()}
        size="sm"
      >{getLocalization('translate')}</Button>
    </Container>
  );
}
