import { Action } from 'typescript-fsa';
import { createSelector } from 'reselect';
import { MODULES, ModuleSelectionInterface, SingleInstanceInterface } from '../Interfaces/ModuleSelectionInterface';
import { StateInterface } from '../Interfaces/StateInterface';
import { FORMS, GALLERY, MODULE_SELECTION } from '../actions/actionTypes';
import { globalWindow } from '../global/global';
import { DataPoint } from '../Interfaces/DataPoint';
import ReducerBuilder from './ReducerBuilder';
import { getForm } from './formsReducer';

export const INITIAL_STATE: ModuleSelectionInterface = {
  selected: MODULES.FEED,
};

type PayloadType = MODULES | SingleInstanceInterface;

function toggleSelection(state: ModuleSelectionInterface, { payload }): ModuleSelectionInterface {
  let selected: MODULES = payload;
  if (selected === MODULES.MAP && state.selected === MODULES.TABLE) {
    selected = MODULES.MAP_AND_TABLE;
  }
  if (selected === MODULES.TABLE && state.selected === MODULES.MAP) {
    selected = MODULES.MAP_AND_TABLE;
  }
  // Clicking on map when map and table is selected, then remove map
  if (state.selected === MODULES.MAP_AND_TABLE && selected === MODULES.MAP) {
    selected = MODULES.TABLE;
  } else if (state.selected === MODULES.MAP_AND_TABLE && selected === MODULES.TABLE) {
    selected = MODULES.MAP;
  }
  if (selected === state.selected) {
    selected = MODULES.FEED;
  }
  return { ...state, selected };
}

function setGallerySelection(state: ModuleSelectionInterface): ModuleSelectionInterface {
  return { ...state, selected: MODULES.GALLERY };
}

const setSingleInstance = (state: ModuleSelectionInterface, { payload }): ModuleSelectionInterface => {
  return { ...state, singleInstance: payload };
};

const unsetSingleInstance = (state: ModuleSelectionInterface): ModuleSelectionInterface => {
  globalWindow.location.hash = '';
  return { ...state, singleInstance: undefined };
};

const onFormsLoaded = (state: ModuleSelectionInterface, { payload }): ModuleSelectionInterface => {
  const hash = globalWindow.location.hash;
  if (hash !== '') {
    const hashParts = hash.substring(1, hash.length).split('/');
    const form =  getForm(payload.forms, payload.formLanguage || '', hashParts[0]);
    // payload.find((f) => f.ref === hashParts[0]);
    if (form) {
      const dp: DataPoint = {
        // row_id: Number(hashParts[2]) > 0 ? Number(hashParts[2]) : undefined,
        questionnaire_id: form.ref,
        formId: form.id,
        id: hashParts[1],
      };
      const singleInstance: SingleInstanceInterface = {
        model: form,
        dataPoint: dp,
        newAnswer: false,
      };
      return { ...state, singleInstance: singleInstance };
    }
  }
  return state;
};

const updateOpenedDataPoint = (state, { payload }): ModuleSelectionInterface => {
  return {
    ...state,
    singleInstance: {
      ...state.singleInstance,
      updatedDataPoint: payload,
    },
  };
};

const onDashboardLoaded = (state, { payload }) => {
  const selected = payload.find((d => d.showOnLogin));
  if (selected) {
    return {
      ...state,
      selected: MODULES.DASHBOARD
    };
  }
  return state;
};

export default new ReducerBuilder<ModuleSelectionInterface, Action<PayloadType>>()
  .setInitialState(INITIAL_STATE)
  .addReducer(MODULE_SELECTION.TOGGLE, toggleSelection)
  .addReducer(MODULE_SELECTION.SET_SINGLE_INSTANCE, setSingleInstance)
  .addReducer(MODULE_SELECTION.UNSET_SINGLE_INSTANCE, unsetSingleInstance)
  .addReducer(GALLERY.SELECT_FORM, setGallerySelection)
  .addReducer(FORMS.LOAD, onFormsLoaded)
  .addReducer(MODULE_SELECTION.UPDATE_OPENED_DATAPOINT, updateOpenedDataPoint)
  .addReducer('dashboard/dashboardsLoaded', onDashboardLoaded)
  .build();

// Selectors
export const moduleSelectionSelector = (state: StateInterface): ModuleSelectionInterface => state.moduleSelection;

export const singleInstanceSelector = createSelector<
StateInterface,
ModuleSelectionInterface,
SingleInstanceInterface | undefined
>([moduleSelectionSelector], (moduleSelection) => moduleSelection.singleInstance);
