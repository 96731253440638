import * as React from 'react';
import './styles/FormComponent.scss';
import bind from 'bind-decorator';
import { QuestionPageInterface } from 'Interfaces/Forms/QuestionPageInterface';
import { Form, Pagination } from 'react-bootstrap';
import { QuestionInterface, questionTypes } from 'Interfaces/Forms/QuestionInterface';
import { FormInterface } from 'Interfaces/Forms/FormsInterface';
import { uniq } from 'lodash';
import { isNullOrUndefined } from 'utils/utils';
import { LooseObject } from '../../Interfaces/LooseObject';
import { DataPoint } from '../../Interfaces/DataPoint';
import { Locations } from '../../Interfaces/LocationInterface';
import { ClientPersistInterface } from '../../Interfaces/ClientPersistInterface';
import FormUtils from './utils/FormUtils';
import { renderQuestions } from './utils/qnrenderer';
import { CreatedByTimeComponent } from './elements/CreatedByTimeComponent';
import { SubFormField } from './elements/SubFormField';
import { validateForm } from './utils/Validator';
import { getFormUtils } from './utils/FormUtilsHolder';
import { getQuestionPage, updateSubFormTables } from './utils/utils';
import TaskParent from './elements/TaskParent';
import DataPointTranslation from './elements/DataPointTranslation';

interface Props {
  model: FormInterface;
  forms: FormInterface[];
  updateAnswer?: (value: LooseObject) => void;
  dataPoint: DataPoint;
  newAnswer?: boolean;
  parentModel?: FormInterface;
  parentDataPoint?: DataPoint;
  parentQuestion?: QuestionInterface;
  clientPersist: ClientPersistInterface;
  locationHierarchy?: Locations;
  updateClientPersist?: (clientPersist: ClientPersistInterface) => void;
  readOnly: boolean;
}

interface State {
  dataPoint: DataPoint;
  updated: boolean;
  newAnswer: boolean;
  counter: number;
  page: number;
  pageList: number[];
  recording: boolean;
}

export default class FormComponent extends React.Component <Props, State> {
  private formUtils: FormUtils;
  constructor(props) {
    super(props);
    const { dataPoint, newAnswer, model, clientPersist } = this.props;
    this.formUtils = getFormUtils(model);
    this.state = {
      dataPoint: dataPoint, // newAnswer ? this.initDataPoint(newDataPoint) : newDataPoint,
      updated: false,
      newAnswer: !newAnswer ? false : newAnswer,
      counter: Date.now(),
      page: this.getPage(),
      recording: false,
      pageList: model.showPerPage && model.questionPage ? model.questionPage.map((page, index) => {
        if (
          page.question &&
          page.question.length > 0 &&
          !page.deleted &&
          `${page.hide}`.indexOf(clientPersist.roles.toLowerCase()) === -1
        ) {
          return index;
        }
        return null;
      }).filter(v => v !== null) : []
    };
  }

  @bind
  private getPage() {
    const { model, dataPoint } = this.props;
    if (model.showPerPage) {
      if (dataPoint['galleryId']) {
        const values = Object.keys(dataPoint);
        for (const key of values) {
          if (Array.isArray(dataPoint[key])) {
            const subforms = dataPoint[key];
            for (const v of subforms) {
              if (v['files']) {
                const found = v['files'].findIndex(f => f.id === dataPoint['galleryId']);
                if (found > -1) {
                  const qn = this.formUtils.getQuestion(key);
                  if (qn.pageIndex) {
                    return qn.pageIndex;
                  }
                }
              }
            }
          }
        }
      }
      return 0;
    }
    return -1;
  }

  /* @bind
  private initDataPoint(dataPoint: DataPoint) {
    const questions = this.formUtils.getQuestions();
    const { parentQuestion } = this.props;
    const ids = keys(questions);
    forEach(ids, (id) => {
      const qn = questions[id];
      if (qn.default) {
        if (qn.type === 'IntQuestion' || qn.type === 'FloatQuestion' ||
          (qn.type === 'CalculatedValueQuestion' && qn.numericOnly)) {
          dataPoint[id] = Number(qn.default);
        } else {
          dataPoint[id] = questions[id].default;
        }
      } else {
        if (qn.type === 'LookupValuesQuestion') {
          const options = qn.lookupValue && qn.lookupValue.split(';').length > 1;
          if (options) {
            const validOptions = parseScript(this.formUtils.getLookupOptions(qn.id), qn, parentQuestion);
            const key = keys(validOptions);
            if (key.length === 1) {
              dataPoint[id] = key[0];
            }
          }
        }
      }
    });
    return dataPoint;
  } */

  @bind
  private updateAnswer(answer: DataPoint) {
    if (isNullOrUndefined(answer['recording'])) {
      if (this.props.updateClientPersist) {
        this.props.updateClientPersist({...this.props.clientPersist, sessionTime: Date.now() });
      }
      this.setState((prevState) =>
        ({ dataPoint: {...prevState.dataPoint, ...answer}, updated: true, counter: Date.now() }));
      if (window.onbeforeunload === null) {
        window.onbeforeunload = () => {
          return confirm('Exit the data viewer?');
        };
      }
    } else {
      this.setState({ recording: answer['recording']});
    }
  }

  @bind
  private getFormElements(): JSX.Element[] {
    const {
      model, forms, updateAnswer, parentModel, parentDataPoint, parentQuestion, clientPersist, readOnly
    } = this.props;
    const { dataPoint, newAnswer, page } = this.state;
    let elements: JSX.Element[] = [];

    const getPageElements = (currentPage: QuestionPageInterface) => {
      if (!model.isChild) {
        elements.push(
          <div key={`page_${currentPage.pageNumber}_${model.ID}`} className="form-group col-md-12 col-lg-12 col-sm-12">
            <hr />
            <h3 id={`${currentPage.pageNumber}`}>{currentPage.name ? currentPage.name : ''}</h3>
          </div>
        );
      }
      const els = renderQuestions(currentPage.question || [], dataPoint, false, forms, updateAnswer ?
        updateAnswer : this.updateAnswer, this.formUtils,
      newAnswer, clientPersist, parentModel, parentDataPoint, parentQuestion, undefined, readOnly);
      if (els.length > 0) {
        elements = elements.concat(els);
      }
    };

    if (model.questionPage) {
      if (page > -1) {
        getPageElements(model.questionPage[this.state.pageList[page]]);
      } else {
        for (const questionPage of model.questionPage) {
          if (
            questionPage.question &&
            questionPage.question.length > 0 &&
            !questionPage.deleted &&
            `${questionPage.hide}`.indexOf(clientPersist.roles.toLowerCase()) === -1
          ) {
            getPageElements(questionPage);
          }
        }
      }
    }

    if (dataPoint.id && !model.isChild) {
      elements.push((
        <CreatedByTimeComponent
          key={`createdby`}
          createdbyUsername={dataPoint.createdbyUsername}
          created={dataPoint.created}
        />
      ));
    }
    return elements;
  }

  /*
    For task form use this function to render the form.
    Visibility
      Status != approved, approvedwithcomments, done
        - HIDE: approveddate, toapprove, approvedby, doneby, donedate

    assigndate, approveddate disabled.

    Status is
      assigned
        Set value
          - assigndate - today
          - donedate, doneby - ''
          -
        Hidden
          - toapprove, donedate, doneby, approvedby

      approved, approvedwithcomments
        approveddate = today

        Visibility
          - approveddate, approvedby = visible

      done
        Set value
          donedate = today
        Visibility
          donedate = visible
      commented
        approveddate, toapprove, approvedby = visible
  */
  private getTaskElements(): JSX.Element[] {
    const {
      model, forms, updateAnswer, clientPersist, parentQuestion, parentModel, parentDataPoint, readOnly
    } = this.props;
    const { dataPoint, newAnswer } = this.state;
    let elements: JSX.Element[] = [];
    if (parentQuestion) {
      elements.push(<SubFormField key={`${parentQuestion.id}`} question={{}} parentQuestion={parentQuestion}/>);
    }
    for (const page of model.questionPage) {
      if (!model.isChild) {
        elements.push(
          <div key={`page_${page.pageNumber}_${model.ID}`}>
            <hr />
            <h3 id={page.pageNumber}>{page.name ? page.name : ''}</h3>
          </div>
        );
      }
      if (page.question) {
        const els = renderQuestions(page.question, dataPoint, false, forms, updateAnswer ?
          updateAnswer : this.updateAnswer, this.formUtils, newAnswer, clientPersist, parentModel,
        parentDataPoint, undefined, undefined, readOnly);
        if (els.length > 0) {
          elements = elements.concat(els);
        }
      }
    }
    if (model.isChild && !parentQuestion && !parentModel) {
      elements.push((
        <TaskParent task={dataPoint} />
      ));
    }
    return elements;
  }

  @bind
  public isRecording() {
    return this.state.recording;
  }

  @bind
  public validateForm() {
    const { dataPoint } = this.state;
    const { model, forms, clientPersist } = this.props;

    const valid = validateForm(model, dataPoint, forms, clientPersist);
    if (!valid.valid) {
      const invalidValidationRules = dataPoint.invalidValidationRules || {};
      if (valid.question && valid.question.type === questionTypes.VALIDATION_RULE_QUESTION) {
        const allVariables: string[] = uniq(
          valid.question.script.match(/_[0-9a-zA-Z_$]*\.[0-9a-zA-Z_.$]*|_[0-9a-zA-Z_$]*/g)
        );
        const formUtils = getFormUtils(model);
        const questionIds = allVariables.map(variable => {
          const id = formUtils.getQuestionId(variable);
          return id;
        });
        invalidValidationRules[valid.question.id] = questionIds;
      }
      const newDataModel = Object.assign({}, dataPoint, {
        validate: true, invalidValidationRules: invalidValidationRules
      });
      this.setState({ dataPoint: newDataModel });
    }
    return valid;
  }

  @bind
  public getDataModel(): DataPoint {
    const { dataPoint } = this.state;
    return {...dataPoint};
  }

  @bind
  public getUpdated(): boolean {
    return this.state.updated;
  }

  @bind
  public getFormUtils() {
    return this.formUtils;
  }

  @bind
  public updateDataPoint(response: DataPoint) {
    const newDataPoint = {...this.state.dataPoint};
    newDataPoint['id'] = response.id;
    newDataPoint.row_id = response.row_id;
    newDataPoint._id = response.row_id;
    if (response.newIds && response.newRowIds) {
      updateSubFormTables(newDataPoint, response.newIds, response.newRowIds);
    }
    this.setState({ dataPoint: newDataPoint });
  }

  @bind
  public updateMergedDataPoint(mergeData: DataPoint) {
    const dp = {...this.state.dataPoint, ...mergeData};
    this.setState({ dataPoint: dp });
  }

  public static getDerivedStateFromProps(props: Props) {
    const { model, parentQuestion } = props;
    /**
     * When the form component is opened in a modal, then we need to update the datapoint state
     * with the values passed in the modal.
     * Otherwise we use the values here as is.
     * Also, this applies when datapoint is updated by custom updateAnswer function, not the one in form component.
     * This happens in Sub/Task forms when opened from a parent form.
     */
    if (model.isChild && parentQuestion) {
      return { dataPoint: props.dataPoint };
    }
    return null;
  }

  @bind
  private getPager() {
    const { pageList, page } = this.state;

    const changePage = (change) => {
      const newPage = page + change;
      this.setState({ page: (newPage < 0 || newPage === pageList.length) ? 0 : newPage });
    };

    return (
      <>
        <Pagination.Prev
          onClick={() => changePage(-1)}
        />
        <Pagination.Item
          active
        >
          {`${page + 1}`}
        </Pagination.Item>
        <Pagination.Next
          onClick={() => changePage(1)}
        />
      </>
    );
  }

  @bind
  private getPagerDropDown() {
    const { model } = this.props;
    const { pageList, page } = this.state;

    return (
      <div className="col-12">
        <Form.Group controlId="exampleForm.ControlSelect1">
          <Form.Label>Select page</Form.Label>
          <select
            className="form-control"
            onChange={(e) => this.setState({ page: Number(e.target.value)})}
          >
            {pageList.map((p, index) => {
              return (
                <option
                  selected={`${index}` === `${page}`}
                  value={index}
                  key={`${model.ref}-page-select-${index}`}
                >
                  {model.questionPage[p].name || `Page ${index + 1}`}
                </option>
              );
            })}
          </select>
        </Form.Group>
      </div>
    );
  }

  public componentDidMount() {
    const { model } = this.props;
    if (model.showPerPage) {
      const hash = window.location.hash.split('/');
      if (hash[4]) {
        const qn = this.formUtils.getQuestion(hash[4]);
        if (qn) {
          if (qn.actionPerRow) {
            const page = getQuestionPage(model, hash[4]);
            if (page) {
              const index = model.questionPage?.findIndex(p => p.id === page.id);
              this.setState({ page: index });
            }
          }
        }
      }
    }
  }

  public render(): JSX.Element {
    const { model, readOnly, updateAnswer } = this.props;
    const { pageList, dataPoint } = this.state;
    return (
      <div className="formview container-fluid row">
        {pageList.length > 1 && this.getPagerDropDown()}
        {model.targetLanguage && (
          <DataPointTranslation
            form={model}
            dataPoint={dataPoint}
            readOnly={readOnly}
            updateAnswer={updateAnswer ? updateAnswer : this.updateAnswer}
          />
        )}
        {model.type === 'TASKFORM' ? this.getTaskElements() : this.getFormElements()}
        {pageList.length > 1 ? (
          <div className="col-12">
            <Pagination>
              {this.getPager()}
            </Pagination>
          </div>
        ) : null}
      </div>
    );
  }
}
