import './Brand.scss';
import './RightDropdown.scss';
import * as React from 'react';
import { Form, Modal, Nav, NavDropdown } from 'react-bootstrap';
import bind from 'bind-decorator';
import { MODULES } from 'Interfaces/ModuleSelectionInterface';
import { getLocalization, globalWindow } from '../../global/global';
import { ModalComponentNames } from '../../Interfaces/ModalInterface';
import { languagesEnum } from '../../Interfaces/ClientPersistInterface';
import NavCheckBox from '../components/NavCheckBox';
import { CURRENT_SELECTION, HeaderPropsInterface } from './HeaderContainer';
import TwoFASetting from './TwoFASetting';
import FormLanguage from './FormLanguage';

export interface IExternalProps {
  clientPersist: HeaderPropsInterface['clientPersist'];
  updateClientPersist: HeaderPropsInterface['actions']['updateClientPersist'];
  navigateAddModal: HeaderPropsInterface['actions']['navigateAddModal'];
  navigateRemoveModal: HeaderPropsInterface['actions']['navigateRemoveModal'];
  logout: HeaderPropsInterface['actions']['logout'];
  cookies: HeaderPropsInterface['cookies'];
  toggleSelectedModule: HeaderPropsInterface['actions']['toggleSelectedModule'];
  saveSetting: HeaderPropsInterface['actions']['saveSetting'];
  system: HeaderPropsInterface['system'];
}

interface Props {
  currentSelection: CURRENT_SELECTION;
}

export interface State {
  show: boolean;
}

const TRANSCRIBE_LANGS = [
  {value: 'af', name: 'Afrikaans'},
  {value: 'sq', name: 'Albanian'},
  {value: 'am', name: 'Amharic'},
  {value: 'ar', name: 'Arabic'},
  {value: 'hy', name: 'Armenian'},
  {value: 'az', name: 'Azerbaijani'},
  {value: 'eu', name: 'Basque'},
  {value: 'bg', name: 'Bulgarian'},
  {value: 'bn', name: 'Bengali'},
  {value: 'bs', name: 'Bosnian'},
  {value: 'my', name: 'Burmese (Myanmar)'},
  {value: 'ca', name: 'Catalan'},
  {value: 'zh', name: 'Chinese'},
  {value: 'hr', name: 'Croatian'},
  {value: 'cs', name: 'Czech'},
  {value: 'da', name: 'Danish'},
  {value: 'nl', name: 'Dutch'},
  {value: 'en', name: 'English'},
  {value: 'et', name: 'Estonian'},
  {value: 'fi', name: 'Finnish'},
  {value: 'fil', name: 'Filipino (Philippines)'},
  {value: 'fr', name: 'French'},
  {value: 'gl', name: 'Galician'},
  {value: 'de', name: 'German'},
  {value: 'ka', name: 'Georgian'},
  {value: 'el', name: 'Greek'},
  {value: 'gu', name: 'Gujarati (India)'},
  {value: 'he', name: 'Hebrew'},
  {value: 'hi', name: 'Hindi'},
  {value: 'hu', name: 'Hungarian'},
  {value: 'is', name: 'Icelandic'},
  {value: 'ga', name: 'Irish'},
  {value: 'id', name: 'Indonesian'},
  {value: 'zu', name: 'isiZulu'},
  {value: 'it', name: 'Italian'},
  {value: 'ja', name: 'Japanese'},
  {value: 'jv', name: 'Javanese'},
  {value: 'kn', name: 'Kannada (India)'},
  {value: 'kk', name: 'Kazakh'},
  {value: 'km', name: 'Khmer'},
  {value: 'ko', name: 'Korean'},
  {value: 'lo', name: 'Lao (Laos)'},
  {value: 'lt', name: 'Lithuanian'},
  {value: 'lv', name: 'Latvian'},
  {value: 'mk', name: 'Macedonian'},
  {value: 'ms', name: 'Malay'},
  {value: 'ml', name: 'Malayalam'},
  {value: 'mt', name: 'Maltese'},
  {value: 'mr', name: 'Marathi (India)'},
  {value: 'mn', name: 'Mongolian'},
  {value: 'nb', name: 'Norwegian Bokmål (Norway)'},
  {value: 'ne', name: 'Nepali'},
  {value: 'ps', name: 'Pashto'},
  {value: 'fa', name: 'Persian'},
  {value: 'pl', name: 'Polish'},
  {value: 'pt', name: 'Portuguese'},
  {value: 'pa', name: 'Punjabi (India)'},
  {value: 'ro', name: 'Romanian'},
  {value: 'ru', name: 'Russian'},
  {value: 'sr', name: 'Serbian'},
  {value: 'si', name: 'Sinhala'},
  {value: 'sk', name: 'Slovak'},
  {value: 'sl', name: 'Slovenian'},
  {value: 'so', name: 'Somali'},
  {value: 'es', name: 'Spanish'},
  {value: 'sw', name: 'Swahili'},
  {value: 'sv', name: 'Swedish'},
  {value: 'ta', name: 'Tamil (India)'},
  {value: 'te', name: 'Telugu (India)'},
  {value: 'th', name: 'Thai'},
  {value: 'tr', name: 'Turkish'},
  {value: 'uk', name: 'Ukrainian'},
  {value: 'ur', name: 'Urdu (India)'},
  {value: 'uz', name: 'Uzbek'},
  {value: 'cy', name: 'Welsh'},
  {value: 'vi', name: 'Vietnamese'},
];

export class RightDropdownClass extends React.Component <IExternalProps & Props, State> {

  constructor(props) {
    super(props);
    this.state = {
      show: false
    };
  }
  @bind
  private onChangeRememberFilterSelections(rememberFilterSelections: boolean) {
    this.props.updateClientPersist({rememberFilterSelections});
  }

  @bind
  private openLink(link, name: string = '_parent') {
    if (link.endsWith('=')) {
      link = link + this.props.clientPersist.lang;
    }
    return () => {
      globalWindow.open(link, name);
    };
  }

  @bind
  private onChangeClusterMap(clusterMap: boolean) {
    this.props.updateClientPersist({clusterMap});
    this.props.navigateAddModal({
      component: ModalComponentNames.ConfirmationModal,
      props: {
        onClose: () => this.props.navigateRemoveModal(ModalComponentNames.ConfirmationModal),
        onConfirm: () => {
          this.props.navigateRemoveModal(ModalComponentNames.ConfirmationModal);
          globalWindow.open(window.location.pathname, '_parent');
        },
        localizations: {
          cancel: getLocalization('cancel'),
          confirm: getLocalization('ok'),
          header: (
            <Modal.Title>
              {getLocalization('info')}
            </Modal.Title>
          ),
          body: (
            <React.Fragment>
              {getLocalization('mapClusterReload')}
            </React.Fragment>
          )
        }
      }
    });
  }

  @bind
  private openEmailNotification() {
    this.props.navigateAddModal({
      component: ModalComponentNames.EmailNotificationModal,
      props: {
        onClose: () => this.props.navigateRemoveModal(ModalComponentNames.EmailNotificationModal)
      }
    });
  }

  @bind
  private openChangePassword() {
    this.props.navigateAddModal({
      component: ModalComponentNames.ChangePasswordModal,
      props: {
        onClose: () => this.props.navigateRemoveModal(ModalComponentNames.ChangePasswordModal)
      }
    });
  }

  @bind
  private onChangeLanguage(event) {
    const {target: {value: lang}}: {target: {value: languagesEnum}} = event;
    globalWindow.lang = lang;
    this.props.updateClientPersist({lang});
    const path = globalWindow.location.pathname === '/' ? 'index.jsp' : globalWindow.location.pathname;
    globalWindow.open(`${path}?lang=${lang}`, '_parent');
  }

  @bind
  private onChangeTranscribeLanguage(event) {
    this.props.saveSetting('transcriptionLang', event.target.value);
  }

  @bind
  private handleLogout() {
    const { system } = this.props;
    if (system.status !== 'OK') {
      this.props.navigateAddModal({
        component: ModalComponentNames.AppModal,
        props: {
          title: getLocalization('info'),
          body: (
            <div className={`alert alert-${system.status === 'WARNING' ?  'warning' : 'danger'}`}>
              {this.props.system.statusMessage}
            </div>
          ),
          confirmText: getLocalization('close'),
          onConfirm: () => {
            void this.props.logout(this.props.cookies);
            this.props.navigateRemoveModal(ModalComponentNames.AppModal);
          },
          onClose: () => {
            this.props.navigateRemoveModal(ModalComponentNames.AppModal);
          }
        }
      });
    } else {
      void this.props.logout(this.props.cookies);
    }
  }

  @bind
  private onToggle(isOpen, event, metadata) {
    if (metadata.source === 'select') {
      this.setState({ show: true });
    } else {
      this.setState({ show: isOpen });
    }
  }

  @bind
  private autoSaveOn(checked) {
    this.props.saveSetting('autoSaveEnabled', checked);
  }

  public componentDidMount(): void {
    const { clientPersist } = this.props;
    if (clientPersist.passwordExpired) {
      this.openChangePassword();
    }
  }

  public render(): JSX.Element {
    const { clientPersist, currentSelection } = this.props;
    const {userName, epaymentUser } = clientPersist;
    const payments = Boolean(epaymentUser) && (
      <React.Fragment>
        <NavDropdown.Item as={'li'} onClick={this.openLink('/payments.jsp')}>
          {getLocalization('payments')}
        </NavDropdown.Item>
        <NavDropdown.Divider />
      </React.Fragment>
    );
    const hide = globalWindow.mobile && clientPersist.roles.indexOf('enumerator') !== -1;
    return (
      <Nav className={'RightDropdown'}>
        <NavDropdown
          title={userName ? userName : ''}
          id="user-nav-dropdown"
          alignRight
          onToggle={this.onToggle}
          show={this.state.show}
          className={''}
        >
          {payments}
          {currentSelection === 'dataviewer' ? (
            <>
              <NavDropdown.Item onClick={() => this.props.toggleSelectedModule(MODULES.DATA_URLS)}>
                {getLocalization('dataUrls')}
              </NavDropdown.Item>
              {clientPersist.roles.indexOf('enumerator') === -1 && (
                <NavDropdown.Item onClick={() => this.props.toggleSelectedModule(MODULES.MANAGE_MAPS)}>
                  {getLocalization('manageMaps')}
                </NavDropdown.Item>
              )}
              <NavDropdown.Divider />
              <NavDropdown.Item as={'li'}>
                <Form>
                  <Form.Label>{getLocalization('transcriptionLanguage')}</Form.Label>
                  <Form.Control
                    as="select"
                    value={this.props.clientPersist.transcriptionLang}
                    placeholder="select"
                    size="sm"
                    onChange={this.onChangeTranscribeLanguage}
                  >
                    <option />
                    {TRANSCRIBE_LANGS.map((lang) =>
                      (<option key={`lang-${lang.value}`} value={lang.value}>{lang.name}</option>)
                    )}
                  </Form.Control>
                </Form>
              </NavDropdown.Item>
              <FormLanguage />
              {clientPersist.require2FA === 'true' ? <TwoFASetting /> : null}
              {!hide && (
                <React.Fragment>
                  <NavCheckBox
                    label={getLocalization('filterselections')}
                    checked={this.props.clientPersist.rememberFilterSelections}
                    onChange={this.onChangeRememberFilterSelections}
                  />
                  <NavCheckBox
                    label={getLocalization('clustermap')}
                    checked={this.props.clientPersist.clusterMap}
                    onChange={this.onChangeClusterMap}
                  />
                  <NavCheckBox
                    label={getLocalization('autoSaveOn')}
                    checked={this.props.clientPersist.autoSaveEnabled ? true : false}
                    onChange={this.autoSaveOn}
                  />
                  <NavDropdown.Divider />
                </React.Fragment>
              )}
            </>
          ) : null}
          <NavDropdown.Item
            onClick={this.openEmailNotification}
          >
            {getLocalization('emailnotification')}
          </NavDropdown.Item>
          <NavDropdown.Item
            onClick={this.openChangePassword}
          >
            {getLocalization('changepasswd')}
          </NavDropdown.Item>
          <NavDropdown.Divider />
          <NavDropdown.Item as={'li'}>
            <Form>
              <Form.Label>{getLocalization('dataViewerLanguage')}</Form.Label>
              <Form.Control
                as="select"
                value={this.props.clientPersist.lang}
                placeholder="select"
                size="sm"
                onChange={this.onChangeLanguage}
              >
                <option value={languagesEnum.en}>English</option>
                <option value={languagesEnum.fi}>Finnish</option>
                <option value={languagesEnum.fr}>French</option>
                <option value={languagesEnum.de}>German</option>
                <option value={languagesEnum.it}>Italian</option>
                <option value={languagesEnum.es}>Spanish</option>
                <option value={languagesEnum.se}>Swedish</option>
              </Form.Control>
            </Form>
          </NavDropdown.Item>
          <NavDropdown.Divider />
          <NavDropdown.Item
            as={'li'}
            onClick={this.handleLogout}
          >
            {getLocalization('logout')}
          </NavDropdown.Item>
        </NavDropdown>
      </Nav>
    );
  }
}

export default RightDropdownClass;
