import { JSONValue } from 'Interfaces/JsonInterface';
import { ModalComponentNames, ModalInterface } from 'Interfaces/ModalInterface';
import { StateInterface } from 'Interfaces/StateInterface';
import { getAccountUserStats, importUsers } from 'actions/adminUserActions';
import { getGroups } from 'actions/groupActions';
import { getLocalization } from 'global/global';
import * as React from 'react';
import Papa from 'papaparse';
import { toast } from 'react-toastify';
import { saveAs } from 'file-saver';
import { Form, ProgressBar } from 'react-bootstrap';
import Select from 'react-dropdown-select';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import GenericModal from 'views/Modals/GenericModal';
import { navigateRemoveModal } from 'actions/navigationAction';

interface ImportUsersModalProps {
  onClose: () => void;
}

export default function ImportUsersModal(props: ImportUsersModalProps) {
  const groups = useSelector((state: StateInterface) => state.groups);
  const dispatch = useDispatch();

  const disableSSO = {
    usesSso: false,
    appleSignin: false,
    samlSso: false,
    aadSso: false
  };

  const [user, setUser] = React.useState<{[key: string]: JSONValue}>({});
  const [file, setFile] = React.useState<File | null>(null);
  const [validate, setValidate] = React.useState(false);
  const [saving, setSaving] = React.useState(false);

  React.useEffect(() => {
    if (!groups.loaded) {
      dispatch(getGroups());
    }
  }, [props]);

  const selectedGroup = React.useCallback((id) => {
    return groups.collection.filter(u => u.id === id);
  }, [user['groupId'], groups]);

  const onFileSelected = (e) => {
    if (e.target.files.length > 0) {
      const files: FileList = e.target.files;
      let i = 0;
      let f: File;
      while (i < files.length) {
        f = files[i];
        if (!(f.type.match('text/csv') || f.type.match('text/plain') ||
        (f.type.match('application/vnd.ms-excel') && f.name.endsWith('.csv')))) {
          return;
        }
        setFile(f);
        i++;
      }
    }
  };

  const body = saving ? (
    <p>
      <ProgressBar
        striped={true}
        now={100}
        animated={true}
      />
    </p>
  ) : (
    <>
      <Form.Group>
        <Form.Label>{getLocalization('selectUsersImportFile')}</Form.Label>
        <Form.Control
          id="exampleFormControlFile1"
          type="file"
          accept='.csv'
          onChange={onFileSelected}
          isInvalid={validate && !file}
        />
        <Form.Text>{getLocalization('importUsersInfo')}</Form.Text>
      </Form.Group>
      <Form.Group>
        <Form.Label>{getLocalization('selectUserGroup')}</Form.Label>
        <Select
          multi={false}
          options={groups.collection}
          onChange={(u) => setUser({...user, groupId: u.length === 1 ? u[0].id : ''})}
          values={selectedGroup(user.groupId)}
          labelField='name'
          valueField='id'
          searchBy='name'
          searchable
          clearable
          placeholder={''}
          create={false}
          className={`form-control form-control-sm ${!user.groupId && validate ? 'is-invalid' : ''}`}
        />
      </Form.Group>
      <Form.Group>
        <Form.Label>{getLocalization('singleSignOn')}</Form.Label>
        <Form.Check
          label={getLocalization('googleSignin')}
          id={'google-sign-in-modal'}
          checked={user['usesSso']}
          onChange={(e) => setUser({...user, ...disableSSO, usesSso: e.target.checked})}
        />
        <Form.Check
          label={getLocalization('appleSignin')}
          id={'apple-sign-in-modal'}
          checked={user['appleSignin']}
          onChange={(e) => setUser({...user, ...disableSSO, appleSignin: e.target.checked})}
        />
        <Form.Check
          label={getLocalization('samlSignin')}
          id={'saml-sso-in-modal'}
          checked={user['samlSso']}
          onChange={(e) => setUser({...user, ...disableSSO, samlSso: e.target.checked})}
        />
        <Form.Check
          label={getLocalization('azureSignin')}
          id={'azure-sign-in-modal'}
          checked={user['aadSso']}
          onChange={(e) => setUser({...user, ...disableSSO, aadSso: e.target.checked})}
        />
      </Form.Group>
      <Form.Group>
        <Form.Check
          label={getLocalization('sendinvitationemail')}
          id={'sendinvitationemail-modal'}
          checked={user['sendInvitationEmail']}
          onChange={(e) => setUser({...user, sendInvitationEmail: e.target.checked})}
        />
      </Form.Group>
      <Form.Group>
        <Form.Check
          label={getLocalization('defineUsersActive')}
          id={'define-Users-Active-modal'}
          checked={user['active']}
          onChange={(e) => setUser({...user, active: e.target.checked})}
        />
      </Form.Group>
      <Form.Group>
        <Form.Label>{getLocalization('emailNotification')}</Form.Label>
        <Form.Check
          label={getLocalization('newdatanotification')}
          id={'newdatanotification-modal'}
          checked={user['newDataNotification']}
          onChange={(e) => setUser({...user, newDataNotification: e.target.checked})}
        />
        <Form.Check
          label={getLocalization('editeddatanotification')}
          id={'editeddatanotification-modal'}
          checked={user['updatedDataNotification']}
          onChange={(e) => setUser({...user, updatedDataNotification: e.target.checked})}
        />
        <Form.Check
          label={getLocalization('notifydataupdated')}
          id={'notifydataupdated-modal'}
          checked={user['updatedCreatedDataNotification']}
          onChange={(e) => setUser({...user, updatedCreatedDataNotification: e.target.checked})}
        />
      </Form.Group>
    </>
  );

  const doImportUsers = async () => {
    if (file) {
      const json = await importUsers(file, user);
      if (json) {
        setSaving(false);
        if (json['status'] === 'FAIL') {
          if (!json['message']) {
            toast('unknownErrorImporting', {
              type: toast.TYPE.ERROR,
              closeButton: false,
              hideProgressBar: true,
            });
          } else {
            toast(getLocalization('errorImporting'), {
              type: toast.TYPE.ERROR,
              closeButton: false,
              hideProgressBar: true,
            });
            const blob = new Blob([json['message']], {type: "text/csv;charset=utf-8"});
            saveAs(blob, "failed-users.txt");
          }
        } else {
          toast(json['message'], {
            type: toast.TYPE.SUCCESS,
            closeButton: false,
            hideProgressBar: true,
          });
          dispatch(navigateRemoveModal(ModalComponentNames.ImportUsersModal));
        }
      }
      console.log('save');
      console.log(file);
    }
  };

  const onSave = async () => {
    if (saving) {
      return;
    }
    if (!file || !user['groupId']) {
      setValidate(true);
      return;
    }
    setSaving(true);
    if (!user['active']) {
      void doImportUsers();
      return;
    }
    const stats = await getAccountUserStats();
    if (stats && stats.activeUsers) {
      const reader = new FileReader();
      reader.onload = (() => {
        return (e) => {
          const text = e.target?.result;
          if (text && typeof text === 'string') {
            const parsed = Papa.parse(text);
            if (parsed.data) {
              const pricingPlan = stats['pricingPlan'];
              let overLimit = false;
              if (pricingPlan === 'Per Transaction') {
                const officeUsers = parsed.data.reduce((accu, user) => user[4] !== 'enumerator' ? accu + 1 : accu, 0);
                const limit = Number(stats['maxUsers']) - stats['noOfOfficeUsers'];
                if (officeUsers > limit) {
                  overLimit = true;
                }
              } else {
                const limit = Number(stats['maxUsers']) - stats['activeUsers'];
                if (parsed.data.length > limit) {
                  overLimit = true;
                }
              }
              if (overLimit) {
                const alert = getLocalization('overLimitAlert');
                toast(alert.replace(/{{limit}}/g, stats['maxUsers'])
                  .replace(
                    /{{current}}/g, stats[pricingPlan === 'Per Transaction' ? 'noOfOfficeUsers' : 'activeUsers']
                  ), {
                  type: toast.TYPE.ERROR,
                  closeButton: false,
                  hideProgressBar: true,
                });
                setSaving(false);
              } else {
                void doImportUsers();
              }
            }
          }
        };
      })();
      reader.readAsText(file);
    } else {
      setSaving(false);
    }
  };

  return (
    <GenericModal
      visible={true}
      title={getLocalization('importUsers')}
      cancel={props.onClose}
      cancelText={getLocalization('cancel')}
      confirmText={getLocalization('importUsers')}
      onConfirm={() => void onSave()}
      body={body}
    />
  );
}

export const showAdminImport = () => {
  const modalProps: ModalInterface<ImportUsersModalProps> = {
    component: ModalComponentNames.ImportUsersModal,
    props: {
      onClose: () => {
        (window as any).ReactAppBridge.ReduxActions.navigateRemoveModal(ModalComponentNames.ImportUsersModal);
      },
    }
  };
  (window as any).ReactAppBridge.ReduxActions.navigateAddModal(modalProps);
};
