import { ReactCookieProps } from 'react-cookie';
import { FormsLoadedInterface } from '../Interfaces/Forms/FormsInterface';
import * as formsApi from '../api/formsApi';
import { filtersSelectedFormType } from '../Interfaces/FiltersMenuInterface';
import { StateInterface } from '../Interfaces/StateInterface';
import {
  formsForFiltersSelector,
  LoadFormHierarchyPayload,
  ValidationListUpdatePayload,
} from '../reducers/formsReducer';
import { loadHierarchyCounts } from '../api/formsApi';
import { actionCreator } from './';
import { loadHierarchy } from './hierarchyActions';
import { FORMS, TOTAL_POI_COUNT } from './actionTypes';
import { getSchedules } from './scheduleActions';
import { autoLogout } from './systemActions';

export const loadForms = actionCreator<FormsLoadedInterface>(FORMS.LOAD);

export const loadTotalPOICount = actionCreator<number>(TOTAL_POI_COUNT.LOAD);

export const getFormsAndHierarchyAndPOICount = (cookies: ReactCookieProps['cookies']) => {
  return async (dispatch, getState) => {
    const { clientPersist } = getState();
    if (cookies) {
      const { forms, totalPOICount, hierarchy } = await formsApi.loadForms(clientPersist.user_id, cookies);
      if (forms) {
        dispatch(loadForms({forms, formLanguage: clientPersist.formLanguage}));
        dispatch(loadTotalPOICount(totalPOICount));
        dispatch(getSchedules());
      } else {
        dispatch(autoLogout());
      }
      if (hierarchy) {
        dispatch(loadHierarchy(hierarchy));
      }
    }
  };
};

export const loadFormHierarchyCounts = actionCreator<LoadFormHierarchyPayload>(FORMS.LOAD_HIERARCHY_COUNTS);

export const fetchFormHierarchy = (selectedForm: filtersSelectedFormType) => {
  return async (dispatch, getState: () => StateInterface) => {
    const form = formsForFiltersSelector(getState()).find((f) => f.ref === selectedForm.ref);
    if (form && !form.hierarchyCounts) {
      const hierarchyCounts = await loadHierarchyCounts(form);
      if (!hierarchyCounts) {
        dispatch(autoLogout());
      }
      dispatch(
        loadFormHierarchyCounts({
          ref: selectedForm.ref,
          hierarchyCounts,
        }),
      );
    }
  };
};

/**
 *
 * @param formId The form identifier
 * @param questionId Question id
 * @param value new validation list item
 * @returns
 */
export const addNewValidationListItem = (formId: string, questionId: string, value: string) => {
  return async (dispatch, getState: () => StateInterface): Promise<boolean> => {
    return new Promise((resolve) => {
      const response = formsApi.doAddNewValidationListItem({
        formId,
        questions: {
          [questionId]: [value]
        }
      },
      dispatch, getState
      );
      void response
        .then((res) => res.json())
        .then((json) => {
          if (json.status === 'OK') {
            dispatch(updateValidationList({ formId, questionId, value }));
            resolve(true);
          }
          resolve(false);
        });
    });
  };
};

export const removeValidationListItem = (formId: string, questionId: string, value: string) => {
  return async (dispatch, getState: () => StateInterface): Promise<boolean> => {
    return new Promise((resolve) => {
      const response = formsApi.doRemoveValidationListItem({
        formId,
        remove: {
          [questionId]: [value]
        }
      },
      dispatch, getState
      );
      void response
        .then((res) => res.json())
        .then((json) => {
          if (json.status === 'OK') {
            dispatch(removeValidationList({ formId, questionId, value }));
            resolve(true);
          }
          resolve(false);
        });
    });
  };
};

export const updateValidationList = actionCreator<ValidationListUpdatePayload>(FORMS.ADD);

export const removeValidationList = actionCreator<ValidationListUpdatePayload>(FORMS.REMOVE);
