import * as React from 'react';
import bind from 'bind-decorator';
import { uniq } from 'lodash-es';
import { Button, Col, Form, InputGroup, Row } from 'react-bootstrap';
import MoreTextComponent from 'views/Table/MoreTextComponent';
import { LooseObject } from '../../../Interfaces/LooseObject';
import { CalculatedPropsInterface } from '../Containers/CalculatedValueQuestionContainer';
import { getLocalization, globalWindow } from '../../../global/global';
import { updateScriptObject } from '../utils/ScriptUtils';
import { calculateValue, checkValidationRuleError, transformCalculatedValueLinks } from '../utils/utils';
import { canView } from '../utils/RolesChecker';
import { isNullOrUndefined } from '../../../utils/utils';
import { getFormUtils } from '../utils/FormUtilsHolder';
import QuestionLabel from './QuestionLabel';
import TextInputComponent from './TextInputComponent';

interface State {
  value: null | string | number;
  obj: LooseObject;
  localSPSSToId: LooseObject;
  firstTime: boolean;
  errorText?: string;
}

const _Edited = `_Edited`;
export default class CalculatedValueQuestion extends React.Component <CalculatedPropsInterface, State> {

  constructor(props) {
    super(props);
    const { dataPoint, question } = this.props;
    const componentState: State = {
      value : dataPoint[question.id] ? dataPoint[question.id] : question.default ? question.default : '',
      obj: {} as LooseObject,
      localSPSSToId: {} as LooseObject,
      firstTime: true
    };
    this.setObjValues(componentState);
    this.state = componentState;
  }

  /*
    When doing the script evaluation, we will need to set the object with _spss -> value mapping.
    This function sets the _spss -> value mapping and also the _spss -> id mapping.
    _spss -> id mapping allows for easier retrieving of values from the data model.
  */
  @bind
  private setObjValues(state: State) {
    const {
      formUtils, forms, parentQuestion, question, parentModel,
      // dataPoint, locationHierarchy, users, parentDataPoint, pois
    } = this.props;
    const obj: LooseObject = {}; // mapping for _spss -> value
    const localSPSSToId: LooseObject = {}; // mapping for _spss -> id
    if (parentQuestion && parentQuestion.type === 'ReferenceTableQuestion') {
      const subForm = forms.find( f => f.ref === parentQuestion.value);
      if (subForm) {
        if (question.script?.indexOf(subForm.referenceName) !== -1) {
          return {...state, obj, localSPSSToId};
        }
      } else {
        const fu = getFormUtils(parentModel);
        const qn = fu.getQuestion(parentQuestion.value);
        if (qn && question.script.indexOf(qn.spssvariable) !== -1) {
          return {...state, obj, localSPSSToId};
        }
      }
    }
    const allVariables: string[] = uniq(question.script?.match(/_[0-9a-zA-Z_$]*\.[0-9a-zA-Z_.$]*|_[0-9a-zA-Z_$]*/g));

    for (const v of allVariables) {
      if (v === '_editor' || v === '_username') {
        obj[v] = globalWindow.userName;
        localSPSSToId[v] = v;
      } else if (v === '_parntfld') {
        obj[v] = '';
        localSPSSToId[v] = 'subfrmfld';
      } else if (v.indexOf('.') !== -1) { // this may be summing a table, a lookup value.
        const prefix = v.substr(0, v.indexOf('.'));
        const questionId = formUtils.getQuestionId(prefix);
        localSPSSToId[v] = questionId;
      } else {
        const id = formUtils.getQuestionId(v);
        localSPSSToId[v] = id;
      }
    }

    /* const response = updateScriptObject(
      localSPSSToId,
      obj,
      formUtils,
      dataPoint,
      forms,
      locationHierarchy,
      users,
      parentModel,
      parentDataPoint,
      pois,
      question,
      parentQuestion
    );*/
    state.obj = obj; // response.updatedObj;
    state.localSPSSToId = localSPSSToId;
    return state;
  }

  @bind
  private handleChange(value: string | number) {
    const { updateAnswer, question } = this.props;
    this.setState({ value }, () => {
      const newAns = {};
      newAns[question.id] = question.numericOnly && value !== '' ? Number(value) : value;
      newAns[`${question.id}${_Edited}`] = true; // !value ? false :
      updateAnswer(newAns);
    });
  }

  @bind
  private restoreCalculation() {
    const { updateAnswer, question } = this.props;
    const newState = this.setObjValues({...this.state});
    this.setState({...newState, value: '' }, () => {
      const newAns = {};
      newAns[question.id] = '';
      newAns[`${question.id}${_Edited}`] = null; // !value ? false :
      updateAnswer(newAns);
    });
  }
  /*
    This is a lifecycle react method. https://reactjs.org/docs/react-component.html#static-getderivedstatefromprops
    If any value that affects the skip has changed, we evaluate the scripta nd set the new value to the state.
  */
  public static getDerivedStateFromProps(props: CalculatedPropsInterface, state: State) {
    const { obj, localSPSSToId, value } = state;
    const { dataPoint, formUtils, updateAnswer, forms, locationHierarchy, parentModel, parentDataPoint, parentQuestion,
      question, pois, users } = props;

    const shouldCalculate = () => {
      if (isNullOrUndefined(question.script)) {
        return false;
      }
      if (
        dataPoint?.freezedQuestions?.[question.id] || dataPoint[`${question.id}${_Edited}`] === true
      ) {
        return false;
      }
      return true;
    };

    const referenceTableCalculation = () => {
      if (parentQuestion && parentQuestion.type === 'ReferenceTableQuestion') {
        const subForm = forms.find( f => f.ref === parentQuestion.value);
        if (subForm && question.script.indexOf(subForm.referenceName) !== -1) {
          return true;
        }
        if (!subForm && parentModel) {
          const fu = getFormUtils(parentModel);
          const qn = fu.getQuestion(parentQuestion.value);
          if (qn && question.script.indexOf(qn.spssvariable) !== -1) {
            return true;
          }
        }
      }
      return false;
    };

    if (!shouldCalculate() || referenceTableCalculation()) {
      return null;
    }

    const response = updateScriptObject(
      localSPSSToId,
      obj,
      formUtils,
      dataPoint,
      forms,
      locationHierarchy,
      users,
      parentModel,
      parentDataPoint,
      pois,
      question,
      parentQuestion
    );

    const { updated, updatedObj, vars } = response;
    const calc = calculateValue(updated, question, dataPoint);
    const isEmpty = () => isNullOrUndefined(value) || value === '';
    if (updated || (calc) || (question.runOnCreate && isEmpty()) || isNullOrUndefined(value)) {
      const { question } = props;
      let script = question.script;

      const calculate = () => {
        try {
          script = script.trim().startsWith('return') || script.trim().indexOf('return') ? script.replace('return ', '')
            : script;
          return eval(vars.join(' ') + script);
        } catch (e) {
          return null;
        }
      };
      let result = calculate();

      const getResult = (res) => {
        let finalResult = (Number.isNaN(res) || res === null) ? '' : res;
        finalResult = (finalResult === '') && question['default'] ? question['default'] : finalResult;
        if (question.numericOnly) {
          finalResult = Number(finalResult);
          if (Number.isNaN(finalResult)) {
            finalResult = '';
          }
        }
        if (finalResult === Infinity || finalResult === -Infinity) {
          finalResult = 0;
        }
        if ('null' === `${res}` || 'undefined' === `${res}`) {
          return '';
        }
        return finalResult;
      };
      result = getResult(result);

      const round = (val) => {
        if (`${val}`.indexOf('.') !== -1) {
          const decimals = `${val}`.substring(`${val}`.indexOf('.') + 1);
          if (decimals.length > 5) {
            val = Math.round(Number(val) * 1000000) / 1000000;
          }
        }
        return val;
      };
      if (typeof result === 'number') {
        result = round(result);
      }
      if  (dataPoint[question.id] !== result && state.value !== result && `${state.value}` !== `${result}`) {
        const newValue = {};
        newValue[question.id] = result;
        updateAnswer(newValue);
      }
      return { obj : updatedObj, value : result };
    } else if (dataPoint['validate']) {
      if (!question.optional && value === '') {
        return { hasError: true };
      }
      let errorText: string | undefined;
      if (dataPoint['invalidValidationRules']) {
        const ivr = dataPoint['invalidValidationRules'];
        const errors = checkValidationRuleError(question.id, ivr, formUtils);
        errorText = errors.errorText;
      }
      return { errorText };
    }
    return null;
  }

  public shouldComponentUpdate(nextProps, nextState: State) {
    const { question, dataPoint } = this.props;
    const key = `${question.id}${_Edited}`;
    return this.state.value !== nextState.value ||
      this.state.errorText !== nextState.errorText ||
      dataPoint[key] !== nextProps.dataPoint[key];
  }

  public render(): JSX.Element | null {
    const { value, errorText } = this.state;
    const { question, formUtils, dataPoint, clientPersist, isTable, isSubQuestion, edit } = this.props;
    const isHorizontal = () => !isTable && question.showValueInParallel &&
      !formUtils.getModel().responsiveLayout &&
      !`${question.text}`.endsWith('---') ? true : false;
    const horizontal = isHorizontal();
    const required = question.optional ? null : (<span className="text-danger">{` * `}</span>);
    if (question.hideInSingleInstance || !canView(question, clientPersist)) {
      return null;
    }
    const renderValue = typeof value === 'number' && value % 1 > 0 ?
      `${Math.round(Number(value) * 100) / 100}` :
      !isNullOrUndefined(value) ? `${transformCalculatedValueLinks(`${value}`)}` : '';

    const control = question.calculatedEditable ? (
      <InputGroup>
        <TextInputComponent
          type={question.numericOnly ? 'number' : 'text'}
          name={question.id}
          value={value}
          disabled={!edit}
          onChange={this.handleChange}
        />
        <InputGroup.Append>
          <Button
            onClick={this.restoreCalculation}
            title={getLocalization('recalculate')}
            disabled={!dataPoint[`${question.id}${_Edited}`]}
          >
            <i className="fa fa-rotate-right" />
          </Button>
        </InputGroup.Append>
      </InputGroup>
    ) : isTable && renderValue.length > 100 ? (
      <>
        <span
          className="text-info"
          dangerouslySetInnerHTML={{__html: renderValue ? `${renderValue}`.substring(0, 100) : ''}}
        />
        <MoreTextComponent value={renderValue} question={question} />
      </>
    ) : (
      <span
        className={`text-info ${!isTable && 'ml-2'}`}
        dangerouslySetInnerHTML={{__html: renderValue ? `${renderValue}` : ''}}
      />
    );
    return (
      <Form.Group
        as={horizontal ? Row : undefined}
        className={`form-group ${
          !horizontal ? formUtils.getResponsiveView(question, isSubQuestion) : 'container-fluid'
        }`}
      >
        <QuestionLabel
          question={question}
          dataPoint={dataPoint}
          formUtils={formUtils}
          horizontal={horizontal}
        >
          {required}
        </QuestionLabel>
        {horizontal ? (
          <Col sm={10} className="form-label col-form-label">
            {control}
          </Col>
        ) : control}
        {(
          <div className="invalid-value-feedback">{errorText}</div>
        )}
      </Form.Group>
    );
  }
}
