import '../styles/DigitalSignature.scss';
import * as React from 'react';
import SignatureCanvas from 'react-signature-canvas';
import { Button, Container, Form } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { questionTypes } from 'Interfaces/Forms/QuestionInterface';
import { getLocalization } from 'global/global';
import { useAppDispatch } from 'index';
import { getPresignedUrl } from 'actions/file';
import { uploadToS3 } from 'api/pois';
import { dataURItoBlob } from 'actions/utils/pois';
import { FileInterface } from 'Interfaces/DataPoint';
import SelectQuestion from './SelectQuestion';
import { Props } from './SelectQuestion';

export default function DigitalSignatureQuestion(props: Props) {

  const { question, dataPoint, updateAnswer } = props;
  const dispatch = useAppDispatch();
  const signPad = React.useRef<SignatureCanvas>();
  const abortController = React.useRef(new AbortController());

  const [signDisabled, setSignDisabled] = React.useState(true);
  const [dateNameQuestions] = React.useState(() => {
    if (question.triggerValues && question.triggerValues.triggerValue) {
      for (const triggerValue of question.triggerValues.triggerValue) {
        if (triggerValue.value === 'Yes') {
          return triggerValue.action.subQuestions.question.filter(
            q => (q.type === questionTypes.DATE_QUESTION || q.type === questionTypes.STRING_QUESTION) && !q.inVisible
          ).map(q => q.id);
        }
      }
    }
    return [];
  });

  React.useEffect(() => {
    if (dateNameQuestions.length > 0) {
      const notFilled = dateNameQuestions.some(id => {
        if (!dataPoint[id]) {
          return true;
        }
        return false;
      });
      if (notFilled) {
        setSignDisabled(true);
      } else {
        setSignDisabled(false);
      }
    } else {
      setSignDisabled(false);
    }
  }, [dataPoint]);

  const clear = () => {
    if (signPad.current) {
      signPad.current.clear();
    }
  };

  const sign = () => {
    if (signPad.current) {
      const imgUrl = signPad.current.getTrimmedCanvas().toDataURL('image/png');
      const updateDataPoint = (fileItem: FileInterface) => {
        const newAnswer = dataPoint['files'] ? [...dataPoint['files']] : [];
        updateAnswer({ files: newAnswer.concat(fileItem), [question.id]: true });
      };
      const file = dataURItoBlob(imgUrl);
      const fileItem: FileInterface  = {
        mimeType: 'image/png',
        fileSize: file.size,
        fileName: file.name,
        questionId: question.id,
        tid: Math.floor(Math.random() * 1000000)
      };
      dispatch(getPresignedUrl({ mimeType: 'image/png', thumbnail: false})).then(json => {
        if (json.payload && json.payload.id) {
          uploadToS3(file, json.payload.url, abortController.current.signal).then(() => {
            fileItem.id = json.payload.id;
            fileItem.url = json.payload.accessUrl;
            updateDataPoint(fileItem);
            toast(getLocalization('fileUploaded'), {
              type: toast.TYPE.SUCCESS,
              autoClose: 3000
            });
          }).catch((e) => {
            console.log(e);
            console.log('error uploading file');
            toast(getLocalization('errorUploadingFile'), {
              type: toast.TYPE.ERROR,
              autoClose: 3000
            });
            fileItem.file = file;
            fileItem.url = imgUrl;
            updateDataPoint(fileItem);
            if ('unmounted' !== e) {
              console.log(e);
            }
          });
        } else {
          fileItem.file = file;
          fileItem.url = imgUrl;
          updateDataPoint(fileItem);
          console.log('not uploaded');
        }
      }).catch(e => {
        fileItem.file = file;
        fileItem.url = imgUrl;
        updateDataPoint(fileItem);
        console.log(e);
      });
      console.log(imgUrl);
    }
  };

  return (
    <>
      <SelectQuestion
        {...props}
      >
        {dataPoint[question.id] !== true ? (
          <Form.Group>
            <SignatureCanvas
              ref={signPad}
              penColor='green'
              canvasProps={{height: 200, width: 500, className: 'signature-canvas'}}
            />
            <Container className="signature-buttons">
              <Button
                variant="danger"
                size="sm"
                onClick={clear}
              >
                {getLocalization('clear')}
              </Button>
              <Button
                size="sm"
                onClick={sign}
                disabled={signDisabled}
              >
                {getLocalization('sign')}
              </Button>
            </Container>
          </Form.Group>
        ) : null}
      </SelectQuestion>
    </>
  );
}
