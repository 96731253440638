import { getLocalization } from 'global/global';
import { useAppSelector } from 'index';
import { DataPoint } from 'Interfaces/DataPoint';
import { FormInterface } from 'Interfaces/Forms/FormsInterface';
import * as React from 'react';
import { isNullOrUndefined } from 'utils/utils';
import { ConfirmationModal } from 'views/Modals/ConfirmationModal';
import GenericModal from 'views/Modals/GenericModal';
import FormComponent from 'views/SingleInstance/FormComponent';
import { getFormUtils } from 'views/SingleInstance/utils/FormUtilsHolder';

interface Props {
  subform: DataPoint;
  dataPoint: DataPoint;
  edit: boolean;
  parentModel: FormInterface;
  updateAnswer: (answer: DataPoint) => void;
}

export default function SubformInstance(props: Props) {
  const { subform, dataPoint, edit, parentModel } = props;

  const [show, setShow] = React.useState(false);
  const [recording, setRecording] = React.useState(false);
  const [saving, setSaving] = React.useState(false);
  const [confirmCancel, setConfirmCancel] = React.useState(false);
  const [instance, setInstance] = React.useState<DataPoint | undefined>();
  const [subformData, setSubformData] = React.useState(subform);

  const clientPersist = useAppSelector(state => state.clientPersist);
  const forms = useAppSelector(state => state.forms.collection);

  const subForm = forms.find(f => f.ref === subform.questionnaire_id);

  const parentQuestion = React.useMemo(() => {
    const fu = getFormUtils(parentModel);
    if (subform['subfrmfld']) {
      const formQn = subform['subfrmfld'].split('_');
      const qn = fu.getQuestion(formQn[1]);
      return qn;
    }
    return undefined;
  }, [parentModel, subform]);

  if (parentQuestion) {
    React.useEffect(() => {
      const newData = dataPoint[parentQuestion.id].find(s => s.id && s.id === subform.id);
      if (newData) {
        setSubformData(newData);
      }
    }, [dataPoint[parentQuestion.id]]);
  }

  React.useEffect(() => {
    if (!recording && saving) {
      saveSubFormInstance();
      setSaving(false);
    }
  }, [recording]);

  const updateSubFormAnswer = (answer: DataPoint) => {
    if (!isNullOrUndefined(answer['recording'])) {
      setRecording(answer.recording);
    } else {
      setInstance({...instance, ...answer});
    }
  };

  const saveSubFormInstance = () => {
    if (recording) {
      setSaving(true);
      setConfirmCancel(true);
      return;
    }
    if (parentQuestion && instance) {
      const newAnswer = dataPoint[parentQuestion.id].map((dp) => {
        if (dp.id && dp.id === instance.id) {
          return instance;
        }
        return dp;
      });
      props.updateAnswer({[parentQuestion.id] : newAnswer});
      setShow(false);
    }
    console.log('update');
  };

  const confirmCancelModal = () => confirmCancel ? (
    <ConfirmationModal
      onConfirm={() => {
        setConfirmCancel(false);
        /**
         *  If saving is true, meaning save was first initiated but recording was taking place.
         *  So we set recording to false. When the effect is triggered because of change in recording value,
         *  we initiate saving again.
         */
        if (saving) {
          setRecording(false);
        } else {
          setInstance(undefined);
        }
        setShow(false);
      }}
      onClose={() => setConfirmCancel(false)}
      localizations={{
        cancel: getLocalization('no'),
        confirm: getLocalization('yes'),
        confirmStyle: 'danger',
        header: (<h4>{getLocalization('confirm')}</h4>),
        body: (
          <p>
            {getLocalization(recording ? 'recordingAlert' : 'cancelEditingSubform')}
          </p>
        )
      }}
    />
  ) : null;

  const modal = show && subForm && instance ? (
    <GenericModal
      visible={show}
      cancel={edit ? () => setConfirmCancel(true) : undefined}
      onConfirm={saveSubFormInstance}
      cancelText={edit ? getLocalization('cancel') : undefined}
      confirmText={getLocalization('return')}
      dialogClassName={'large-modal'}
      body={(
        <FormComponent
          model={subForm}
          forms={forms}
          dataPoint={instance}
          updateAnswer={updateSubFormAnswer}
          parentDataPoint={dataPoint}
          parentModel={parentModel}
          parentQuestion={parentQuestion}
          newAnswer={false}
          clientPersist={clientPersist}
          readOnly={!edit}
        />
      )}
    />
  ) : null;

  return (
    <>
      {confirmCancel && confirmCancelModal()}
      {modal}
      <a href="#" onClick={() => {
        setShow(true);
        setInstance({...subformData});
      }}>{subform?.Name}</a>
    </>
  );
}
